import { DEFAULT_LANGUAGE } from './feature-flags';
import imgSEO from '../images/assets/seo-image.jpg';
import imgFilter from '../images/assets/ig-filter.jpg';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = false;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Stela Natalia Mulia';
export const GIRL_NAME_SHORT = 'Stela';
export const GIRL_FATHER_NAME = `Marthinus Lumalessil`;
export const GIRL_MOTHER_NAME = `Dra Tri Lestari Wahyuningsih`;
export const GIRL_PARENT_NAME = {
  en: `The daughter of Mr. ${GIRL_FATHER_NAME}<br /> and Almh. Mrs. ${GIRL_MOTHER_NAME}`,
  id: `Putri dari Bapak ${GIRL_FATHER_NAME}<br /> dan Almh. Ibu ${GIRL_MOTHER_NAME}`,
}
// -> boy section
export const BOY_NAME = 'Lucia Bara Susanto';
export const BOY_NAME_SHORT = 'Bara';
export const BOY_FATHER_NAME = `Budianto`;
export const BOY_MOTHER_NAME = `Nurul Chotimah`;
export const BOY_PARENT_NAME = {
  en: `The son of Mr. ${BOY_FATHER_NAME}<br /> and Mrs. ${BOY_MOTHER_NAME}`,
  id: `Putra dari Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`,
}
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'lbarasusanto';
export const IG_GIRL = 'stelanataliaa';

//Instagram Filter
export const IG_FILTER = 'https://www.instagram.com/ar/1748450482259802/';
export const IMG_IG_FILTER = imgFilter;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = imgSEO;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato.net`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1694345400;
export const EPOCH_RESEPSI_START = 1694345400;
export const EPOCH_END_EVENT = 1694349000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-09-10');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_AKAD_TIME = '18.30 - 21.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Waterboom Lippo Cikarang';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Madiun No.Kav. 115, Cibatu, Kabupaten Bekasi, Jawa Barat 17550`;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = WEDDING_AKAD_TIME;
export const WEDDING_RESEPSI_LOC_NAME = WEDDING_AKAD_LOC_NAME;
export const WEDDING_RESEPSI_LOC_ROAD = WEDDING_AKAD_LOC_ROAD;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

  export const WEDDING_DATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD • MM • YYYY');
/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://StelamemBara.com';
export const BROADCAST_WEDDING_LOCATION = `Waterboom Lippo Cikarang`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/Qt2RLz18ER4HWcoZ9?g_st=ic`;
export const GOOGLE_MAPS_ADDRESS = BROADCAST_WEDDING_LOCATION;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Reach by Robert Shirey Kelly`;
export const SOUND_URL = 'https://youtu.be/r2Vypzey_3k';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/HWAC9WY4Xbg';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/HWAC9WY4Xbg';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/WEC5RezD5jU`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/WEC5RezD5jU`;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbzPsIAaUaD7PQoCBfTTWHD0Mit9iHcY5jXyxNCr11Bk_b5ki8bKBITWQzNu1VXi7WDr/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';
